@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;300;400;500;700;900&family=Oswald:wght@200;300;400;500;600;700&display=swap");

ul {
  list-style: none;
}

html {
  font-size: 0.09765625vw;
}

a.bachibachiover {
  overflow: hidden;
  position: relative;
  z-index: 1;
  cursor: pointer;
}

a.bachibachiover::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  background: #fff;
  opacity: 0.5;
  transition: transform 0.1s;
  content: "";
  transform-origin: center center;
  transform: scale(0, 1);
  cursor: pointer;
}
a.bachibachiover::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -2;
  content: "";
  cursor: pointer;
}
/* hover */
a.bachibachiover:hover:before {
  transform-origin: left bottom;
  transform: scale(2, 2);
  content: "";
  cursor: pointer;
}

@media screen and (min-width: 1024px) {
  html {
    font-size: 1px;
  }
}

@media screen and (max-width: 750px) {
  html {
    font-size: 0.133333333vw;
  }
}

body {
  background: #040000;
}

body,
html {
  padding: 0px;
  margin: 0px;
  height: 100%;
  font-family: "Noto Sans JP", sans-serif;
}
h1,
h2,
h3,
h4,
h5 {
  margin: 0px;
  font-weight: 400;
  font-family: "Oswald", sans-serif;
}
p,
dl,
dd,
dt {
  margin: 0px;
}
ol,
ul {
  margin: 0px;
  padding: 0px;
  list-style-type: none;
}
figure {
  margin: 0px;
}

.pc-only {
  display: block;
}
.sp-only {
  display: none;
}

.bg-mainvisual {
  background: url(../common/bg_main.png);
  background-repeat: no-repeat;
  background-position: top center;
}

.pdb60rem {
  padding-bottom: 60rem;
}

.main-header {
  padding-top: 42rem;
  position: fixed;
  z-index: 300;
  width: 100%;
  .inner {
    max-width: 90%;
    width: 1024rem;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    .logo {
      width: 154rem;
      height: 59rem;
    }
    .gnav {
      ul {
        display: flex;
        justify-content: right;
        li {
          padding-left: 25rem;
          a {
            color: #fff;
            text-decoration: none;
            font-size: 14rem;
          }
        }
      }
    }
  }
}

.main-visual {
  overflow: hidden;
  padding-top: 70rem;
  .inner {
    display: flex;
    justify-content: space-between;
    max-width: 90%;
    width: 1024rem;
    margin-left: auto;
    margin-right: auto;
    .left {
      color: #fff;
      width: 277rem;
      .logo {
        padding-top: 140rem;
        padding-bottom: 29rem;
        img {
          width: 277rem;
          height: 108rem;
        }
      }
      .text {
        font-size: 20rem;
        line-height: 34rem;
        margin-bottom: 60rem;
        &:before {
          content: "";
          display: block;
          height: 1px;
          background: url("../common/border_color.jpg");
          background-position: center;
          background-size: cover;
          margin-bottom: 18rem;
        }
        &:after {
          content: "";
          display: block;
          height: 1px;
          background: url("../common/border_color.jpg");
          background-position: center;
          background-size: cover;
          margin-top: 18rem;
        }
      }
    }
    .right {
      padding-top: 60rem;
      position: relative;
      width: 748rem;
      height: 605rem;
      top: 60rem;
      img {
        width: 748rem;
        height: 605rem;
      }
    }
  }
}

.btn-type__gp {
  padding: 20rem;
  display: block;
  border-radius: 10rem;
  text-align: center;
  background-image: linear-gradient(
    90deg,
    rgba(82, 129, 249, 1),
    rgba(177, 58, 254, 1)
  );
  font-size: 20rem;
  color: #fff;
  text-decoration: none;
  box-sizing: border-box;
}

.follow_sns {
}

.section-amp {
  height: 500rem;
  .inner {
    position: relative;
    figure {
      position: relative;
      max-width: 90%;
      width: 1024rem;
      margin-left: auto;
      margin-right: auto;
      img {
        position: absolute;
        top: 0px;
        right: 430rem;
        width: 732rem;
        height: 500rem;
        transition-duration: 0.5s;
        &:first-child {
          width: 1232rem;
          opacity: 0;
        }
        &:nth-child(2) {
          width: 1300rem;
          opacity: 0;
        }
        &:nth-child(3) {
          width: 1632rem;
          opacity: 0;
        }
        &:nth-child(4) {
          width: 1600rem;
          right: 350rem;
          opacity: 0;
        }
        &:nth-child(5) {
          width: 1300rem;
          height: 500rem;
          opacity: 0;
        }
      }
      .demo-1,
      .demo-1_2 {
        transition-duration: 0.5s;
        opacity: 0;
        right: 430rem;
        width: 1232rem;
        height: 500rem;
        &:first-child {
          width: 1232rem;
          position: absolute;
          right: 431rem;
        }
        &:nth-child(3n) {
          width: 1232rem;
          position: absolute;
          right: 431rem;
        }
        .glitch__img {
          width: 1232rem;
          height: 500rem;
          img {
            right: 0rem;
          }
        }
      }
    }
    .right {
      width: 100%;
      position: absolute;
      top: 0px;
      right: 0px;
      color: #fff;
      text-align: right;
      .right-inner {
        width: 990rem;
        margin-left: auto;
        margin-right: auto;
        display: block;
        .cont-box {
          width: 460rem;
          display: inline-block;
          text-align: left;
          padding-top: 70rem;
          h2 {
            font-size: 60rem;
            font-weight: 300;
          }
          .title-sub {
            font-size: 20rem;
            background-image: linear-gradient(
              90deg,
              rgb(82, 129, 249),
              rgb(177, 58, 254)
            );
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            display: inline-block;
          }
          .text-box {
            padding-top: 40rem;
            p {
              font-size: 18rem;
              line-height: 28rem;
              color: #b7b6c2;
            }
          }
        }
      }
    }
  }
}

.section-vision {
  background: url(../common/bg_vision1.png);
  background-repeat: no-repeat;
  background-position: top center;
  padding-bottom: 160rem;
  .inner {
    max-width: 90%;
    width: 1024rem;
    color: #fff;
    margin-left: auto;
    margin-right: auto;
    header {
      h1 {
        font-size: 100rem;
        line-height: 100rem;
        padding-bottom: 65rem;
        font-weight: 300;
      }
    }
    div {
      &.title-sub {
        font-size: 32rem;
        font-family: "Oswald", sans-serif;
        font-weight: 300;
        padding-bottom: 60rem;
        max-width: 725rem;
      }
      p {
        width: 700rem;
        color: #b7b6c2;
        font-size: 18rem;
        line-height: 32rem;
        padding-bottom: 30rem;
        &:last-child {
          padding-bottom: 0px;
        }
      }
    }
  }
}

.section-imgavater {
  .inner {
    max-width: 90%;
    width: 1024rem;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    h2 {
      color: #fff;
      position: absolute;
      width: 100%;
      font-size: 50rem;
      font-weight: 300;
      padding-top: 80rem;
      line-height: 58rem;
      z-index: 100;
    }
    figure {
      img {
        width: 1024rem;
        height: 601rem;
        object-fit: cover;
      }
    }
  }
}

.bg-middle {
  background: url(../common/bg_nfts.png);
  background-repeat: no-repeat;
  background-position: center center;
}

.section-nft {
  padding-top: 120rem;
  .inner {
    color: #fff;
    max-width: 90%;
    width: 1024rem;
    margin-left: auto;
    margin-right: auto;
  }
  .inner {
    header {
      padding-bottom: 80rem;
      h2 {
        font-size: 100rem;
        line-height: 100rem;
      }
      p {
        font-size: 40rem;
        font-family: "Oswald", sans-serif;
        font-weight: 200;
      }
    }
    .title-sub_flex {
      display: flex;
      justify-content: right;
      align-items: center;
      align-content: center;
      .read-sub {
        font-size: 18rem;
        text-align: right;
        margin-right: 30rem;
        color: #d9d9ff;
      }
    }
    .contents {
      h2 {
        background-image: linear-gradient(
          90deg,
          rgba(82, 129, 249, 1),
          rgba(177, 58, 254, 1)
        );
        display: inline-block;
        font-size: 80rem;
        font-weight: 200;
        padding: 0 30rem 0 30rem;
      }
      .title-rev {
        h2 {
          margin-left: 595rem;
        }
        .text-box {
          margin-left: 220rem;
        }
      }
      .text-box {
        width: 800rem;
        font-size: 24rem;
      }
      .img-record {
        padding-top: 40rem;
        display: flex;
        justify-content: space-between;
        figure {
          position: relative;
          width: 512rem;
          height: 437rem;
          img {
            width: 512rem;
            height: 437rem;
          }
          img.now-printing {
            width: 337rem;
            height: 431rem;
          }
          &:nth-child(2n) {
            margin-top: 80rem;
          }
          &:nth-child(3n) {
            margin-top: 160rem;
          }
        }
      }
      .flex-rev {
        display: flex;
        justify-content: space-between;
        flex-direction: row-reverse;
      }
    }
  }
}

.mgt30rem {
  margin-top: 30rem;
}

.selction-roadmap {
  padding-bottom: 160rem;
  padding-top: 130rem;
  header {
    text-align: center;
    color: #fff;
    padding-bottom: 90rem;
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
    h1 {
      font-size: 100rem;
      font-weight: 300;
      line-height: 80rem;
      padding-bottom: 50rem;
    }
    p {
      font-size: 20rem;
      color: #b7b6c2;
    }
  }
  .inner {
    max-width: 90%;
    width: 1024rem;
    display: flex;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    dl {
      position: relative;
      color: #fff;
      border-left: 1px solid #fff;
      padding-left: 70rem;
      width: 400rem;
      box-sizing: border-box;
      &:after {
        content: "";
        position: absolute;
        left: -5rem;
        top: -5rem;
        width: 10px;
        height: 10px;
        background: #fff;
        border-radius: 10px;
      }
      &:before {
        content: "";
        display: block;
        position: absolute;
        top: 0px;
        left: 0px;
        width: 53rem;
        border-top: 1px solid #fff;
      }
      dt {
        font-size: 20rem;
        padding-bottom: 10rem;
        position: relative;
        top: -15rem;
        font-weight: bold;
        .title-sub {
          background-image: linear-gradient(
            90deg,
            rgba(82, 129, 249, 1),
            rgba(177, 58, 254, 1)
          );
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
      dd {
        font-size: 20rem;
        position: relative;
        top: -15rem;
        padding-bottom: 30rem;
        color: #b4b4b4;
        box-sizing: border-box;
      }
      &:last-child {
        dd {
          padding-bottom: 0px;
        }
      }
    }
    .right {
      padding-top: 160rem;
    }
  }
}

.title-rev {
  display: flex;
  flex-wrap: wrap;
  justify-content: right;
  text-align: right;
  padding-top: 100rem;
}

.section-cv {
  &:before {
    background: url("../common/border_color.jpg");
    content: "";
    display: block;
    height: 6rem;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  background: url(../common/sound_wave.png);
  background-position: center bottom;
  .inner {
    padding-top: 100rem;
    padding-bottom: 100rem;
    text-align: center;
    header {
      h2 {
        color: #ffffff;
        font-size: 100rem;
        line-height: 100rem;
        font-weight: 300;
        padding-bottom: 30rem;
      }
      p {
        font-size: 20rem;
        padding-bottom: 60rem;
        color: #b7b6c2;
        width: 540rem;
        margin-left: auto;
        margin-right: auto;
      }
    }
    .cv-snslist {
      display: flex;
      justify-content: space-between;
      max-width: 90%;
      width: 1024rem;
      margin-left: auto;
      margin-right: auto;
      li {
        width: 316rem;
        a {
          display: block;
          width: 316rem;
          text-align: center;
          padding: 35rem;
          box-sizing: border-box;
          border-radius: 100rem;
          font-family: "Oswald", sans-serif;
          font-weight: 300;
          color: #fff;
          text-decoration: none;
          font-size: 30rem;
          &.twitter {
            background: #13adf7;
          }
          &.disco {
            /*background:#4562ff;*/
            background: #bfbfbf;
            color: #949494;
            pointer-events: none;
          }
          &.telegram {
            background: #3e5a64;
          }
        }
      }
    }
  }
}

.footer-main {
  color: #fff;
  padding-top: 60rem;
  padding-bottom: 60rem;
  text-align: center;
  font-size: 14rem;
}

.sp-only {
  display: none;
}

.pc-only {
  display: block;
}

.sns-side {
  position: fixed;
  right: 0px;
  top: 150rem;
  li {
    width: 62rem;
    height: 52rem;
    display: block;
    a {
      display: block;
      width: 62rem;
      height: 52rem;
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      &.twitter {
        background: #13adf7;
        color: #fff;
        font-size: 24rem;
        text-decoration: none;
      }
      &.disco {
        background: #4562ff;
        color: #fff;
        font-size: 24rem;
        text-decoration: none;
        background: #bfbfbf;
        color: #949494;
        pointer-events: none;
      }
      &.telegram {
        background: #3e5a64;
        color: #fff;
        font-size: 24rem;
        text-decoration: none;
      }
    }
  }
}

.btn-trigger {
  position: relative;
  width: 50px;
  height: 34px;
  cursor: pointer;
  z-index: 500;
}

.btn-trigger span {
  position: absolute;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #fff;
  border-radius: 2px;
}
.btn-trigger,
.btn-trigger span {
  display: inline-block;
  transition: all 0.5s;
  box-sizing: border-box;
}
.btn-trigger span:nth-of-type(1) {
  top: 0;
}
.btn-trigger span:nth-of-type(2) {
  top: 16px;
}
.btn-trigger span:nth-of-type(3) {
  bottom: 0;
}

#btn01.active span:nth-of-type(1) {
  -webkit-transform: translateY(20px) rotate(-45deg);
  transform: translateY(20px) rotate(-45deg);
}
#btn01.active span:nth-of-type(2) {
  opacity: 0;
}
#btn01.active span:nth-of-type(3) {
  -webkit-transform: translateY(-20px) rotate(45deg);
  transform: translateY(-20px) rotate(45deg);
  bottom: -7px;
}
.btn-trigger {
  position: fixed;
  top: 45rem;
  right: 40rem;
  display: none;
  z-index: 500;
}

.sp-gnav {
  position: fixed;
  pointer-events: none;
  cursor: pointer;
  transition-duration: 0.5s;
  opacity: 0;
  width: 100%;
  top: 0px;
  left: 0px;
  height: 100%;
  background: #5120fb;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  z-index: 100;
  li {
    text-align: center;
    padding-bottom: 30rem;
    &:last-child {
      padding-bottom: 0px;
    }
    a {
      color: #fff;
      text-decoration: none;
      font-size: 50rem;
    }
  }
}

.size-mainvis__bg {
  width: 1024rem;
  height: 1100rem;
  left: 105rem !important;
}

.tgt span.cur {
  display: block;
}

.tgt {
  opacity: 0;
  span {
    opacity: 0;
    &.cur {
      display: inline-block;
      opacity: 1;
    }
  }
}

.show-contents {
  animation: showcontents 0.5s linear;
  opacity: 1;
  img {
    object-fit: cover;
  }
}

.spake-animate {
  position: relative;
  &:before {
    display: block;
    animation: topBubbles ease-in-out 0.75s forwards;
  }
  &:after {
    display: block;
    animation: bottomBubbles ease-in-out 0.75s forwards;
  }
}

$fuschia: #b03bfe;
$button-bg: $fuschia;
$button-text-color: #fff;
$baby-blue: #f8faff;

.bubbly-button {
  &:before,
  &:after {
    position: absolute;
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    left: -20%;
    z-index: -1000;
    transition: all ease-in-out 0.5s;
    background-repeat: no-repeat;
  }
  &:before {
    display: block;
    top: -75%;
    background-image: radial-gradient(circle, $button-bg 60%, transparent 60%),
      radial-gradient(circle, transparent 60%, $button-bg 60%, transparent 30%),
      radial-gradient(circle, $button-bg 60%, transparent 60%),
      radial-gradient(circle, $button-bg 60%, transparent 60%),
      radial-gradient(circle, transparent 10%, $button-bg 15%, transparent 60%),
      radial-gradient(circle, $button-bg 60%, transparent 60%),
      radial-gradient(circle, $button-bg 60%, transparent 60%),
      radial-gradient(circle, $button-bg 60%, transparent 60%),
      radial-gradient(circle, $button-bg 60%, transparent 60%);
    background-size: 10% 10%, 60% 60%, 15% 15%, 60% 60%, 18% 18%, 10% 10%,
      15% 15%, 10% 10%, 18% 18%;
    //background-position: 0% 80%, -5% 60%, 10% 60%, 60% 0%, 30% 30%, 22% 50%, 50% 50%, 65% 60%, 85% 30%;
  }

  &:after {
    display: block;
    bottom: -75%;
    background-image: radial-gradient(circle, $button-bg 60%, transparent 60%),
      radial-gradient(circle, $button-bg 60%, transparent 60%),
      radial-gradient(circle, transparent 10%, $button-bg 15%, transparent 60%),
      radial-gradient(circle, $button-bg 60%, transparent 60%),
      radial-gradient(circle, $button-bg 60%, transparent 60%),
      radial-gradient(circle, $button-bg 60%, transparent 60%),
      radial-gradient(circle, $button-bg 60%, transparent 60%);
    background-size: 15% 15%, 60% 60%, 18% 18%, 60% 60%, 15% 15%, 10% 10%,
      60% 60%;
    //background-position: 5% 90%, 10% 90%, 10% 90%, 15% 90%, 25% 90%, 25% 90%, 60% 90%, 55% 90%, 70% 90%;
  }
}

.main-visual_wl {
  height: 100%;
  padding-top: 0px !important;
}

.wrapper_wl {
  height: 100%;
  .bg-mainvisual {
    height: 100%;
  }
  .imgloaded {
    height: 100%;
  }
}

.wavecanvas_wl {
  height: 100% !important;
  padding-top: 200rem;
  box-sizing: border-box;
}

.main-visual_wl_header {
  text-align: center;
  position: relative;
  z-index: 500;
  width: 100%;
  h1 {
    width: 100%;
    display: flex;
    align-content: flex-end;
    align-items: flex-end;
    justify-content: center;
    margin-top: 80rem;
    img {
      width: 270rem;
      margin-right: 50rem;
    }
    span {
      color: #fff;
      font-size: 18rem;
      letter-spacing: 14rem;
    }
  }
}

.btn-connect-wallet {
  background-image: linear-gradient(
    90deg,
    rgb(82, 129, 249),
    rgb(177, 58, 254)
  );
  position: absolute;
  top: 0px;
  right: 0px;
  text-align: center;
  line-height: 50rem;
  text-align: center;
  display: flex;
  justify-content: right;
  a {
    font-size: 14rem;
    letter-spacing: 2rem;
    color: #fff;
    text-decoration: none;
    font-family: "Oswald", sans-serif;
    width: 211rem;
    height: 50rem;
    display: block;
    i {
      margin-right: 1px;
    }
  }
}

.btn-connect-over {
  background: #041636;
}

.l-data-number {
  display: flex;
  justify-content: center;
  margin-bottom: 30rem;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  dl {
    display: flex;
    justify-content: center;
    color: #fff;
    font-size: 16rem;
    font-family: "Oswald", sans-serif;
    align-items: center;
    align-content: center;
    dt {
      border: 1px solid #fff;
      padding: 2rem 10rem 2rem 10rem;
      border-radius: 5px;
    }
    dd {
      align-items: center;
      align-content: center;
      font-size: 26rem;
      padding-left: 20px;
      padding-right: 30px;
    }
    &:last-child {
      dd {
        padding-right: 0px;
      }
    }
  }
}

.wh-read-text_failed {
  display: none;
}

.wh-read-text_caution {
  display: none;
}

.main-visual_wl_body {
  border: 1px solid #fff;
  width: 90%;
  max-width: 1100rem;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40rem;
  text-align: center;
  box-sizing: border-box;
  padding-top: 100rem;
  padding-bottom: 100rem;
  z-index: 300;
  position: relative;
  background-color: rgba(15, 33, 82, 0.4);
  box-shadow: 24px 23px 27px -14px rgba(0, 0, 0, 0.6);
  p {
    color: #fff;
    font-size: 14rem;
    line-height: 28rem;
    letter-spacing: 2rem;
    margin-bottom: 30rem;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
  input {
    max-width: 606rem;
    height: 55rem;
    background-color: rgba(255, 255, 255, 0.3);
    border: none;
    border-radius: 5rem;
    z-index: 300;
    position: relative;
    padding: 10rem;
    box-sizing: border-box;
    font-size: 18rem;
    color: #fff;
    margin-bottom: 30rem;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
}

.main-visual_wl_btn {
  max-width: 420rem;
  width: 90%;
  border: none;
  background-image: linear-gradient(
    90deg,
    rgb(82, 129, 249),
    rgb(177, 58, 254)
  );
  color: #fff;
  padding: 22px;
  font-size: 24rem;
  border-radius: 40rem;
  &:before {
    content: "";
    display: inline-block;
    width: 10rem;
    height: 10rem;
    border-right: 1px solid #fff;
    border-bottom: 1px solid #fff;
    transform: rotate(-45deg);
    margin-right: 15rem;
  }
}

.main-visual_wl_btn_stop {
  background: #979797;
}

.main-visual_wl_footer {
  text-align: center;
  color: #fff;
  padding-top: 20rem;
  font-size: 14rem;
  padding-top: 40rem;
}

.btn-connect-owned {
  background: rgb(23, 152, 194);
  background: linear-gradient(
    261deg,
    rgba(23, 152, 194, 1) 0%,
    rgba(34, 208, 199, 1) 100%
  );
}

.display-block {
  display: block !important;
}

.display-none {
  display: none !important;
}

.btn-connect-close {
  background: rgb(194, 23, 136);
  background: linear-gradient(
    261deg,
    rgba(194, 23, 136, 1) 0%,
    rgba(208, 34, 75, 1) 100%
  );
}

.btn-connect-failed {
  background: #7b2130;
}

@keyframes topBubbles {
  0% {
    background-position: 5% 90%, 10% 90%, 10% 90%, 15% 90%, 25% 90%, 25% 90%,
      60% 90%, 55% 90%, 70% 90%;
    filter: brightness(257%) contrast(253%) grayscale(76%) hue-rotate(230deg)
      invert(100%) saturate(300%);
  }
  50% {
    background-position: 0% 80%, 0% 60%, 10% 60%, 60% 0%, 30% 30%, 22% 50%,
      50% 50%, 65% 60%, 90% 30%;
    filter: brightness(257%) contrast(253%) grayscale(76%) hue-rotate(230deg)
      saturate(300%);
  }
  100% {
    background-position: 0% 70%, 0% 10%, 10% 30%, 60% -10%, 30% 60%, 22% 60%,
      50% 60%, 65% 10%, 90% 60%;
    background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
  }
}

@keyframes bottomBubbles {
  0% {
    background-position: 10% -10%, 30% 10%, 55% -10%, 70% -10%, 85% -10%,
      70% -10%, 70% 0%;
    filter: brightness(257%) contrast(253%) grayscale(76%) hue-rotate(230deg)
      invert(100%) saturate(300%);
  }
  50% {
    background-position: 0% 80%, 60% 80%, 45% 60%, 60% 100%, 75% 70%, 95% 60%,
      105% 0%;
    filter: brightness(257%) contrast(253%) grayscale(76%) hue-rotate(230deg)
      saturate(300%);
  }
  100% {
    background-position: 0% 90%, 20% 90%, 45% 70%, 60% 110%, 75% 80%, 95% 70%,
      110% 10%;
    background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
  }
}

@keyframes showcontents {
  0% {
    opacity: 1;
    transform: translate3d(var(--gap-horizontal), 0, 0);
    -webkit-clip-path: polygon(0 2%, 100% 2%, 100% 5%, 0 5%);
    clip-path: polygon(0 2%, 100% 2%, 100% 5%, 0 5%);
  }
  2% {
    -webkit-clip-path: polygon(0 15%, 100% 15%, 100% 15%, 0 15%);
    clip-path: polygon(0 15%, 100% 15%, 100% 15%, 0 15%);
    filter: brightness(257%) contrast(253%) grayscale(76%) hue-rotate(230deg)
      invert(100%) saturate(300%);
  }
  4% {
    -webkit-clip-path: polygon(0 10%, 100% 10%, 100% 20%, 0 20%);
    clip-path: polygon(0 10%, 100% 10%, 100% 20%, 0 20%);
  }
  6% {
    -webkit-clip-path: polygon(0 1%, 100% 1%, 100% 2%, 0 2%);
    clip-path: polygon(0 1%, 100% 1%, 100% 2%, 0 2%);
  }
  8% {
    -webkit-clip-path: polygon(0 33%, 100% 33%, 100% 33%, 0 33%);
    clip-path: polygon(0 33%, 100% 33%, 100% 33%, 0 33%);
    filter: brightness(257%) contrast(253%) grayscale(76%) hue-rotate(230deg)
      invert(100%) saturate(300%);
  }
  10% {
    -webkit-clip-path: polygon(0 44%, 100% 44%, 100% 44%, 0 44%);
    clip-path: polygon(0 44%, 100% 44%, 100% 44%, 0 44%);
  }
  12% {
    -webkit-clip-path: polygon(0 50%, 100% 50%, 100% 20%, 0 20%);
    clip-path: polygon(0 50%, 100% 50%, 100% 20%, 0 20%);
  }
  14% {
    -webkit-clip-path: polygon(0 70%, 100% 70%, 100% 70%, 0 70%);
    clip-path: polygon(0 70%, 100% 70%, 100% 70%, 0 70%);
    filter: brightness(257%) contrast(253%) grayscale(76%) hue-rotate(230deg)
      saturate(300%);
  }
  16% {
    -webkit-clip-path: polygon(0 80%, 100% 80%, 100% 80%, 0 80%);
    clip-path: polygon(0 80%, 100% 80%, 100% 80%, 0 80%);
  }
  18% {
    -webkit-clip-path: polygon(0 50%, 100% 50%, 100% 55%, 0 55%);
    clip-path: polygon(0 50%, 100% 50%, 100% 55%, 0 55%);
  }
  20% {
    -webkit-clip-path: polygon(0 70%, 100% 70%, 100% 80%, 0 80%);
    clip-path: polygon(0 70%, 100% 70%, 100% 80%, 0 80%);
  }
  21.9% {
    opacity: 1;
    transform: translate3d(var(--gap-horizontal), 0, 0);
  }
  22% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    -webkit-clip-path: polygon(0 0, 0 0, 0 0, 0 0);
    clip-path: polygon(0 0, 0 0, 0 0, 0 0);
  }
  23% {
    opacity: 1;
    transform: translate3d(calc(-1 * var(--gap-horizontal)), 0, 0);
    -webkit-clip-path: polygon(0 25%, 100% 25%, 100% 30%, 0 30%);
    clip-path: polygon(0 25%, 100% 25%, 100% 30%, 0 30%);
  }
  28% {
    -webkit-clip-path: polygon(0 3%, 100% 3%, 100% 3%, 0 3%);
    clip-path: polygon(0 3%, 100% 3%, 100% 3%, 0 3%);
  }
  30% {
    -webkit-clip-path: polygon(0 5%, 100% 5%, 100% 20%, 0 20%);
    clip-path: polygon(0 5%, 100% 5%, 100% 20%, 0 20%);
  }
  32% {
    -webkit-clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
    clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
    filter: brightness(257%) contrast(253%) grayscale(76%) hue-rotate(230deg)
      saturate(300%);
  }
  34% {
    -webkit-clip-path: polygon(0 40%, 100% 40%, 100% 40%, 0 40%);
    clip-path: polygon(0 40%, 100% 40%, 100% 40%, 0 40%);
  }
  36% {
    -webkit-clip-path: polygon(0 52%, 100% 52%, 100% 59%, 0 59%);
    clip-path: polygon(0 52%, 100% 52%, 100% 59%, 0 59%);
  }
  38% {
    -webkit-clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
    clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
  }
  40% {
    -webkit-clip-path: polygon(0 75%, 100% 75%, 100% 75%, 0 75%);
    clip-path: polygon(0 75%, 100% 75%, 100% 75%, 0 75%);
  }
  42% {
    -webkit-clip-path: polygon(0 65%, 100% 65%, 100% 40%, 0 40%);
    clip-path: polygon(0 65%, 100% 65%, 100% 40%, 0 40%);
    filter: brightness(257%) contrast(253%) grayscale(76%) hue-rotate(230deg)
      invert(100%) saturate(300%);
  }
  44% {
    -webkit-clip-path: polygon(0 45%, 100% 45%, 100% 50%, 0 50%);
    clip-path: polygon(0 45%, 100% 45%, 100% 50%, 0 50%);
  }
  46% {
    -webkit-clip-path: polygon(0 14%, 100% 14%, 100% 33%, 0 33%);
    clip-path: polygon(0 14%, 100% 14%, 100% 33%, 0 33%);
  }
  48.9% {
    opacity: 1;
    transform: translate3d(calc(-1 * var(--gap-horizontal)), 0, 0);
  }
  50% {
    opacity: 0;
    transform: translate3d(0, 0, 0);
    -webkit-clip-path: polygon(0 0, 0 0, 0 0, 0 0);
    clip-path: polygon(0 0, 0 0, 0 0, 0 0);
  }
  52% {
    opacity: 1;
    transform: translate3d(0, calc(-1 * var(--gap-vertical)), 0)
      scale3d(-1, -1, 1);
    -webkit-clip-path: polygon(0 1%, 100% 1%, 100% 3%, 0 3%);
    clip-path: polygon(0 1%, 100% 1%, 100% 3%, 0 3%);
  }
  53.5% {
    -webkit-clip-path: polygon(0 10%, 100% 10%, 100% 9%, 0 9%);
    clip-path: polygon(0 10%, 100% 10%, 100% 9%, 0 9%);
  }
  54% {
    -webkit-clip-path: polygon(0 5%, 100% 5%, 100% 6%, 0 6%);
    clip-path: polygon(0 5%, 100% 5%, 100% 6%, 0 6%);
  }
  55% {
    -webkit-clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
    clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
    filter: brightness(257%) contrast(253%) grayscale(76%) hue-rotate(230deg)
      saturate(300%);
  }
  57% {
    -webkit-clip-path: polygon(0 10%, 100% 10%, 100% 10%, 0 10%);
    clip-path: polygon(0 10%, 100% 10%, 100% 10%, 0 10%);
  }
  59% {
    -webkit-clip-path: polygon(0 30%, 100% 30%, 100% 25%, 0 25%);
    clip-path: polygon(0 30%, 100% 30%, 100% 25%, 0 25%);
  }
  59.5% {
    -webkit-clip-path: polygon(0 15%, 100% 15%, 100% 16%, 0 16%);
    clip-path: polygon(0 15%, 100% 15%, 100% 16%, 0 16%);
  }
  61% {
    -webkit-clip-path: polygon(0 40%, 100% 40%, 100% 39%, 0 39%);
    clip-path: polygon(0 40%, 100% 40%, 100% 39%, 0 39%);
  }
  63% {
    -webkit-clip-path: polygon(0 20%, 100% 20%, 100% 21%, 0 21%);
    clip-path: polygon(0 20%, 100% 20%, 100% 21%, 0 21%);
  }
  64% {
    -webkit-clip-path: polygon(0 60%, 100% 60%, 100% 55%, 0 55%);
    clip-path: polygon(0 60%, 100% 60%, 100% 55%, 0 55%);
  }
  65.5% {
    -webkit-clip-path: polygon(0 30%, 100% 30%, 100% 31%, 0 31%);
    clip-path: polygon(0 30%, 100% 30%, 100% 31%, 0 31%);
  }
  66% {
    -webkit-clip-path: polygon(0 70%, 100% 70%, 100% 69%, 0 69%);
    clip-path: polygon(0 70%, 100% 70%, 100% 69%, 0 69%);
    filter: brightness(257%) contrast(253%) grayscale(76%) hue-rotate(230deg)
      saturate(300%);
  }
  68% {
    -webkit-clip-path: polygon(0 40%, 100% 40%, 100% 41%, 0 41%);
    clip-path: polygon(0 40%, 100% 40%, 100% 41%, 0 41%);
  }
  69% {
    -webkit-clip-path: polygon(0 80%, 100% 80%, 100% 75%, 0 75%);
    clip-path: polygon(0 80%, 100% 80%, 100% 75%, 0 75%);
  }
  69.5% {
    -webkit-clip-path: polygon(0 50%, 100% 50%, 100% 51%, 0 51%);
    clip-path: polygon(0 50%, 100% 50%, 100% 51%, 0 51%);
  }
  70% {
    -webkit-clip-path: polygon(0 90%, 100% 90%, 100% 90%, 0 90%);
    clip-path: polygon(0 90%, 100% 90%, 100% 90%, 0 90%);
  }
  71% {
    -webkit-clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
    clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
    filter: brightness(257%) contrast(253%) grayscale(76%) hue-rotate(230deg)
      saturate(300%);
  }
  72% {
    -webkit-clip-path: polygon(0 100%, 100% 100%, 100% 99%, 0 99%);
    clip-path: polygon(0 100%, 100% 100%, 100% 99%, 0 99%);
  }
  74% {
    -webkit-clip-path: polygon(0 70%, 100% 70%, 100% 71%, 0 71%);
    clip-path: polygon(0 70%, 100% 70%, 100% 71%, 0 71%);
  }
  75.9% {
    opacity: 1;
    transform: translate3d(0, calc(-1 * var(--gap-vertical)), 0)
      scale3d(-1, -1, 1);
  }
  77% {
    opacity: 0;
    transform: translate3d(0, 0, 0);
    -webkit-clip-path: polygon(0 0, 0 0, 0 0, 0 0);
    clip-path: polygon(0 0, 0 0, 0 0, 0 0);
  }
  80% {
    opacity: 1;
  }
  90% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

* {
  -moz-transition: -moz-transform 1s linear;
  -webkit-transition: -webkit-transform 1s linear;
  -o-transition: -o-transform 1s linear;
  -ms-transition: -ms-transform 1s linear;
  transition: transform 1s linear;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.main-visual_wl_result {
  font-size: 64rem;
  line-height: 74rem;
  text-align: center;
  font-family: "Oswald", sans-serif;
  letter-spacing: 10rem;
}

.main-visual_wl_vailed {
  color: #eddc4e;
  padding-bottom: 30rem;
  display: none;
}

.main-visual_wl_invailed {
  color: #ff0060;
  display: none;
  padding-bottom: 30rem;
}

.wh-read-text_vailed,
.wh-read-text_invailed {
  display: none;
}

.image-clippath {
  -webkit-animation-name: image-clippath;
  animation-name: image-clippath;
  transition: 1.8s;
  animation-duration: 2s;
}

@-webkit-keyframes image-clippath {
  0% {
    clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
  }
  100% {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
}

.img-blur {
  -webkit-animation-name: imageBlur;
  animation-name: imageBlur;
  opacity: 1;
  transition: 0.6s;
}

@-webkit-keyframes imageBlur {
  from {
    opacity: 0;
    -webkit-filter: blur(20px);
    -moz-filter: blur(20px);
    -ms-filter: blur(20px);
    -o-filter: blur(20px);
    filter: blur(20px);
  }

  to {
    opacity: 1;
    -webkit-filter: blur(0px);
    -moz-filter: blur(0px);
    -ms-filter: blur(0px);
    -o-filter: blur(0px);
    filter: blur(0px);
  }
}
@keyframes imageBlur {
  from {
    opacity: 0;
    -webkit-filter: blur(20px);
    -moz-filter: blur(20px);
    -ms-filter: blur(20px);
    -o-filter: blur(20px);
    filter: blur(20px);
  }

  to {
    opacity: 1;
    -webkit-filter: blur(0px);
    -moz-filter: blur(0px);
    -ms-filter: blur(0px);
    -o-filter: blur(0px);
    filter: blur(0px);
  }
}

.wavecanvas {
  overflow: hidden;
  position: absolute;
  width: calc(100% + 100rem);
  height: 1000rem;
  left: -100rem;
}

#waveCanvas {
  height: 400rem;
  width: 100%;
  position: absolute;
  top: 150rem;
}

#waveCanvas2 {
  height: 400rem;
  width: 160%;
  position: absolute;
  top: 250rem;
  transform: rotate(15deg);
}

@media screen and (max-width: 1024px) {
  .section-nft .inner .contents .img-record figure img {
    width: 450rem;
    height: auto;
  }
  .section-nft .inner .contents h2 {
    font-size: 74rem;
  }
}

@media screen and (max-width: 750px) {
  .main-visual_wl_header {
    h1 {
      padding-top: 80rem;
    }
  }
  .btn-connect-wallet {
    a {
      font-size: 28rem;
      width: 300rem;
      padding: 20rem;
    }
  }
  .l-data-number {
    display: block;
    margin-left: auto;
    margin-right: auto;
    dl {
      margin-bottom: 20rem;
      dt {
        font-size: 28rem;
      }
      dd {
        font-size: 28rem;
      }
    }
  }
  .btn-trigger {
    display: block;
  }
  .gnav {
    display: none;
  }
  .sp-only {
    display: block;
  }
  .pc-only {
    display: none;
  }
  .main-visual {
    .inner {
      display: block;
      max-width: 600rem;
      text-align: center;
      .left {
        width: auto;
        .btn-type__gp {
          width: 500rem;
          margin-left: auto;
          margin-right: auto;
          padding: 30rem;
          font-size: 40rem;
          border-radius: 100rem;
        }
        .text {
          width: 500rem;
          margin-left: auto;
          margin-right: auto;
          font-size: 28rem;
          line-height: 50rem;
          &:before {
            margin-bottom: 60rem;
          }
          &:after {
            margin-top: 60rem;
          }
        }
        figure {
          img {
            width: 600rem;
            margin-top: -50rem;
          }
        }
      }
      .logo {
        padding-top: 120rem !important;
        img {
          width: 449rem !important;
          height: 115rem !important;
        }
      }
    }
  }
  .section-amp {
    height: 950rem;
    .inner {
      display: block;
      padding-top: 470rem;
      .left {
        width: 750rem;
        max-width: 100%;
        img {
          left: 0px;
        }
      }
      .right {
        width: 750rem;
        .right-inner {
          width: 750rem;
          .cont-box {
            width: 500rem;
            display: block;
            margin-left: auto;
            margin-right: auto;
            h2 {
              font-size: 65rem;
            }
            .title-sub {
              font-size: 40rem;
            }
            .text-box {
              p {
                font-size: 28rem;
                line-height: 48rem;
              }
            }
          }
        }
      }
    }
  }
  .section-vision {
    .inner {
      width: 600rem;
      div {
        p {
          width: 600rem;
          font-size: 28rem;
          line-height: 50rem;
        }
      }
    }
  }
  .section-imgavater {
    .inner {
      h2 {
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        left: 5%;
      }
      figure {
        img {
          max-width: 100%;
        }
      }
    }
  }
  .section-nft {
    .inner {
      .contents {
        .text-box {
          font-size: 28rem;
          width: 100%;
          padding-bottom: 40rem;
        }
        .img-record {
          display: block;
          figure {
            text-align: center;
            img {
              width: 100%;
              height: auto;
              &.now-printing {
                width: 500rem;
                height: auto;
              }
            }
          }
        }
      }
      .title-sub_flex {
        display: block;
        text-align: right;
        h2 {
          margin-bottom: 40rem;
        }
        .read-sub {
          margin-right: 0px;
          font-size: 28rem;
        }
      }
    }
  }
  .selction-roadmap {
    header {
      p {
        font-size: 28rem;
        text-align: left;
      }
    }
    .inner {
      display: block;
      .left,
      .right {
        dl {
          width: 100%;
          dt {
            font-size: 36rem;
          }
          dd {
            font-size: 24rem;
            padding-bottom: 30rem;
          }
        }
      }
      .right {
        padding-top: 0px;
        dl {
          &:last-child {
            dd {
              padding-bottom: 0px;
            }
          }
        }
      }
    }
  }
  .section-cv {
    .inner {
      header {
        h2 {
        }
        p {
          font-size: 28rem;
          width: 700rem;
        }
      }
      .cv-snslist {
        display: block;
        li {
          width: 100%;
          padding-bottom: 40rem;
          a {
            width: 100%;
            font-size: 40rem;
          }
        }
      }
    }
  }
  .footer-main {
    font-size: 24rem;
  }
  .sns-side {
    display: none;
    li {
      width: 90rem;
      height: 90rem;
      a {
        width: 90rem;
        height: 90rem;
        font-size: 34rem !important;
      }
    }
  }
  .main-header {
    .inner {
      .logo {
        width: 204rem;
        height: 72rem;
      }
    }
  }
  .section-amp .inner .left img {
    left: auto;
    right: 0px !important;
  }
  .section-amp .inner figure .demo-1,
  .section-amp .inner figure .demo-1_2 {
    right: 0px !important;
  }
  .section-amp .inner .left {
    transform: scale(1.5);
    margin-top: -60rem;
  }
  .wrapper {
    overflow: hidden;
  }
  .section-nft .inner .contents .img-record figure {
    margin-left: auto;
    margin-right: auto;
  }
  .img-record_2 figure {
    height: 635rem !important;
  }
  .section-nft .inner .contents .img-record_2 figure:nth-child(3n) {
    margin-top: 80rem !important;
  }
  .selction-roadmap .inner dl:after {
    left: -11rem;
    top: -7rem;
  }
  .sp-record {
    position: relative;
    width: 600rem;
    height: 435rem;
  }
  #waveCanvas {
    top: 300rem;
  }
  #waveCanvas2 {
    top: 300rem;
  }
  .selction-roadmap header h1 {
    padding-bottom: 60rem;
  }
  .selction-roadmap header p {
    line-height: 50rem;
  }
  #waveCanvas {
    height: 550rem;
  }
  .wavecanvas {
    height: 1300rem;
  }
  #waveCanvas2 {
    height: 550rem;
  }
  .section-nft .inner .contents .title-rev h2 {
    margin-left: 0px;
  }
  .section-nft .inner .contents .title-rev .text-box {
    margin-left: 0px;
  }
  .main-visual_wl_body p {
    font-size: 24rem;
    line-height: 38rem;
  }
  .main-visual_wl_body input {
    height: 100rem;
    font-size: 32rem;
  }
  .main-visual_wl_btn {
    width: 60%;
    max-width: 60%;
    border-radius: 100rem;
    font-size: 28rem;
    padding: 24rem;
  }
  .main-visual_wl_footer {
    font-size: 24rem;
    padding-top: 60rem;
  }
}
